<template>
<diV>
  <b-card-code title="Apontamento Produção">
    <b-row>
      <b-col
        md="2"
        xl="2"
      >
        <b-form-group
          label="Filtrar Por:"
        >
          <b-form-select
          v-model="FiltroTela.filtrarPorSelected"
          :options="filtrarPorOptions">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        md="3"
        xl="3"
      >
        <b-form-group
          label="Valor:"          
        >
          <b-form-input
            id="filtrarPorValue"
            placeholder=""
            v-model="FiltroTela.filtrarPorValue"
            required
            autocomplete="off"
          />
        </b-form-group>
      </b-col>

    <b-col
        md="6"
        xl="4"
    >
    <br>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="primary" 
      type="button" @click="Consultar"><span>BUSCAR</span></b-button>

    </div>
    </b-col>
    </b-row>
    
</b-card-code>

<div>
<b-card-code style="min-height: 300px;" >
  <b-table
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
      style="min-height: 300px;"
    >
    <template #cell(idiproc)="row">
             
        <b-button size="sm"
         style="width: 40px;"
          variant="warning"
          @click="RedirecionaDetalhe(row.item,'apontamentoProducaoDetalhe')">
          I
        </b-button>
      </template>
    </b-table>

</b-card-code>
</div>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'

import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    Multiselect,
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BFormInput,
    BPagination,
    BCardBody,
    BCol,
    BButton
    
  },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'ApontamentoProducao',
        Selecionado: false,
        filtrarPorValue: '',
        filtrarPorSelected: 'IDIPROC',
        FiltroComplementar: [],
      },
      filtrarPorOptions: [
        { value: 'IDIPROC', text: 'Nº OP' },
        { value: 'IDIPROC', text: 'Nº Lote' },
      ],
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'idiproc', label: 'AÇÕES'  },
        { key: 'versao', label: 'Versão' }.hide,
        { key: 'codprc', label: 'Cod.Processo'}.hide,
        { key: 'idiatv', label: 'idAtividade'}.hide,
        { key: 'dT_ULTIMOCICLO', label: 'dT_ULTIMOCICLO'}.hide,
        { key: 'hR_ULTIMOCICLO', label: 'hR_ULTIMOCICLO'}.hide,
        { key: 'codprod', label: 'Cod.Produto' },
        { key: 'descrprod', label: 'Produto', sortable: false,tdClass: 'td250' },
        { key: 'qtdproduzir', label: 'Qtd.Produzir'},
        { key: 'descricaoetapa', label: 'Etapa'},
        { key: 'descstatus', label: 'Status'},
      ],
      items: [
      ],
      ModalPRODUTO:{
        TIPO:'PRODUTO',
        ATIVO:'S'
      },
      
    }
  },
  mounted() {
    this.$session.remove('ApontamentoProducao')
    
    //this.ConsultarOpcoes()
    // Seta q quantidade de Linhas no Grid
    this.totalRows = this.items.length
    // Consulta Dados para Filtro na Tela
    this.DescarregarFiltroTela()
  },
  methods: {
    async Consultar() {
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skOperacoesProducao/ConsultaApontamentoProducao?CODUSU=${usuario.codusu}`
      
      this.FiltroTela.FiltroComplementar = []
      
      this.CarregaFiltroTela()

      const ParametroJson = JSON.parse(JSON.stringify(this.FiltroTela))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.items = response.data
          this.totalRows = this.items.length
        } else {
          notification = ConexaoAPI.MsgFiltroSemRetorno()
          AlertaMsg.FormataMsg(notification)
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    DescarregarFiltroTela() {
      const usuario = this.$session.get('usuario')
      const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (Filtrado.Selecionado) {
        // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
        if (Filtrado.filtrarPorValue !== '') {
          this.FiltroTela = Filtrado// Carrega o Filtro
          
          // Descarrega Filtro Complementar
          /*this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
            switch (ftComplementar.parametro) {
            }          
          })*/

          this.Consultar() // Executa o Filtro
        }
      }
    },
    RedirecionaDetalhe(item, rota) {

      this.$session.set('ApontamentoProducao', item)

      const $rota = `${rota}/${item.idiproc}`
      this.$router.push($rota)
    },
  },
  computed: {
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>